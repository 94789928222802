let server
let AUTHORIZE_URL
let AUTHORIZE_PUBLIC_KEY
let AUTHORIZE_LOGIN_ID
let STRIPE_PUBLISHABLE_KEY
console.log('ENV:', process.env.NODE_ENV)
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  server = `http://${process.env.REACT_APP_SERVER}:8080`
  console.log('RUNNING ON SERVER:', server)
  AUTHORIZE_URL = 'https://jstest.authorize.net/v1/Accept.js'
  AUTHORIZE_PUBLIC_KEY = '32m8seeJyLXYfZEt98sNeTBYbwrvtJftxN93p4GGH5Hv9E2C6ezU8p963DJaT4Q5'
  AUTHORIZE_LOGIN_ID = '7f87HgBZ'
  STRIPE_PUBLISHABLE_KEY = 'pk_test_kfEylTWhPBiKMxqCSmaNpsYq'
} else {
  // production code
  server = 'https://apiv2.advanced-wellness.org'
  AUTHORIZE_URL = 'https://js.authorize.net/v1/Accept.js'
  AUTHORIZE_PUBLIC_KEY = '83YnwasM4D2Z8xB8JfTmqvHrmAzsD6XdzwRQA9Y3B5hg7T4z5cJp967HnTGXDSt9'
  AUTHORIZE_LOGIN_ID = '8T73XspT'
  STRIPE_PUBLISHABLE_KEY = 'pk_test_kfEylTWhPBiKMxqCSmaNpsYq'
}

const CONFIG = {
  AUTHORIZE_URL,
  AUTHORIZE_PUBLIC_KEY,
  AUTHORIZE_LOGIN_ID,
  STRIPE_PUBLISHABLE_KEY,
  SocketIO: `${server}/portal`,
  SocketIOPublic: `${server}/public`,
  Boot: `${server}/portal/boot`,
  RegisterFullIntoBucket: `${server}/portal/register-into-bucket/full`,
  BucketRegistrationContent: `${server}/portal/registration-content`,
  RegisterIntoBucket: `${server}/portal/register-into-bucket`,
  RegisterIntoShadowBucket: `${server}/portal/register-into-shadow-bucket`,
  RegisterFullIntoShadowBucket: `${server}/portal/register-into-shadow-bucket/full`,
  ResetPassword: `${server}/portal/auth/reset-password`,
  VerifyResetToken: `${server}/portal/verify-reset-token`,
  RequestResetPassword: `${server}/portal/recover-password`,
  UploadTimelineAttachment: `${server}/portal/uploads/timeline-upload`,
  UpdatePassword: `${server}/portal/update-password`,
  UploadProfilePicture: `${server}/portal/upload-profile-pic`,
  UpdateAvatarUrl: `${server}/portal/update-avatar-url`,
  UpdateProfile: `${server}/portal/update-profile`,
  SendOTP: `${server}/portal/send-otp`,
  VerifyOTPAndDelete: `${server}/portal/verify-otp-and-delete`,
  ChartTimelineUploads: `${server}/portal/chart-timeline-uploads`,
  VerifyRecoverToken: `${server}/portal/verify-recover-token`,
  RecoverAccount: `${server}/portal/recover-account`,
  Logout: `${server}/portal/logout`,
  Payment_NewProfile: `${server}/portal/new-profile-payment`,
  GetPaymentMethods: `${server}/portal/p-profiles`,
  GetBPRDetails: `${server}/portal/get-bpr-details`,
  Payment_ExistingProfile: `${server}/portal/bpr-payment`,
  ActiveSubscriptions: `${server}/portal/active-subscriptions`,
  PaymentHistory: `${server}/portal/payment-history`,
  NewPaymentMethod: `${server}/portal/new-p`,
  DeletePaymentMethod: `${server}/portal/delete-payment-method`,
  UpdateDefaultPaymentMethod: `${server}/portal/update-default-method`,

  GetTimelineMessage: `${server}/portal/get-timeline-messages`,
  deleteTimelineMessage: `${server}/portal/delete-timeline-messages`,
  updateTimelineMessage: `${server}/portal/update-timeline-messages`,

  getThreadSubmissions: `${server}/portal/get-thread-submissions`,
  newThreadSubmission: `${server}/portal/new-thread-submission`,
  getThread: `${server}/portal/get-thread`,

  getPollStudy: `${server}/portal/get-poll-study`,
  submitPollStudy: `${server}/portal/submit-poll-study`,
  submitPollStudyComment: `${server}/portal/submit-poll-study-comment`,
  submitPollStudyWithNewOption: `${server}/portal/submit-poll-study-with-new-option`,

  timelineProductSaleNewProfile: `${server}/portal/timeline-product-sale-new-profile`,
  timelineProductExCustNewPM: `${server}/portal/timeline-product-existing-cutomer-new-payment-method`,
  timelineProductSaleExistingProfile: `${server}/portal/timeline-product-sale-existing-profile`,

  getFeedProductGroupDetails: `${server}/portal/get-feed-product-group-details`,
  timelineProductGroupSaleNewProfile: `${server}/portal/timeline-product-group-sale-new-profile`,
  timelineProductGroupExCustNewPM: `${server}/portal/timeline-product-group-existing-cutomer-new-payment-method`,
  timelineProductGroupSaleExistingProfile: `${server}/portal/timeline-product-group-sale-existing-profile`,

  isMyBucketLive: `${server}/portal/is-my-chart-bucket-online`,
  getLiveSession: `${server}/portal/get-live-session`,
  getGoLiveSessionChats: `${server}/portal/get-golive-session-chats`,
  deleteGoLiveSessionChatMessage: `${server}/portal/delete-live-session-chat`,
  goLiveSessionSaleCheckoutNewProfile: `${server}/portal/go-live-session-sale-checkout-new-profile`,
  goLiveSessionSaleExCustNewPM: `${server}/portal/go-live-session-sale-checkout-existing-cust-new-method`,
  goLiveSessionSaleExistingProfile: `${server}/portal/go-live-session-sale-checkout-existing-profile`,

  fetchThreadProductDetails: `${server}/portal/fetch-thread-product-details`,
  threadProductSaleNewProfile: `${server}/portal/thread-product-sale-new-profile`,
  threadProductExCustNewPM: `${server}/portal/thread-product-existing-cutomer-new-payment-method`,
  threadProductSaleExistingProfile: `${server}/portal/thread-product-sale-existing-profile`,

  bootDummyPatient: `${server}/portal/boot-dummy-patient`,
  logoutIfExists: `${server}/portal/logout-if-exists`,
  getFeedProductStatus: `${server}/portal/get-feed-product-status`,

  // Payment
  checkout: `${server}/portal/checkout`,
  checkoutRequest: `${server}/portal/checkout-request`,
  checkoutSuccess: `${server}/portal/checkout-success`,
  getCheckoutToken: `${server}/portal/get-checkout-token`,
  getPaymentMethods: `${server}/portal/get-patient-payment-methods`,

  // Public goLiveSession
  getPublicGoLiveSession: `${server}/portal/get-public-golive-session`,
  loginPublicGoLiveSession: `${server}/portal/login-golive-session`,
  canIJoin: `${server}/portal/canijoin`,
  createMyChart: `${server}/portal/create-my-chart`,
  getPublicSessionOfferDetails: `${server}/portal/get-public-session-offer-details`,
  goLiveOfferAvailability: `${server}/portal/golive-session-offer-availability`,
  registerLoggedInUser: `${server}/portal/register-logged-in-user-for-golive`,

  getPublicGoLive: `${server}/portal/get-public-go-live`,

  goLiveLogin: `${server}/portal/golive-login`,
  goLiveEnrollmentStatus: `${server}/portal/golive-enrollment-status`,
  enrollMeOnGoLive: `${server}/portal/enroll-me-on-golive`,
  createLead: `${server}/portal/create-lead`,
  givememybucketchart: `${server}/portal/give-me-my-bucket-chart`,

  onlinklogin: `${server}/portal/one-link-login`,

  getInterimDetails: `${server}/portal/get-interim-details`,
  signalGreetings: `${server}/portal/signal-thread-greetings`,

  getPublicPage: `${server}/portal/get-public-page`,
  publicPageRegistration: `${server}/portal/public-page-registration`,
  publicPageSubmission: `${server}/portal/public-page-submission`,
  publicPageThreadStartedSignal: `${server}/portal/public-page-thread-started-signal`,
  publicPageThreadEndedSignal: `${server}/portal/public-page-thread-ended-signal`,
  publicPageSmartReg: `${server}/portal/public-page-smart-reg`,
  publicPageSmartJoin: `${server}/portal/public-page-smart-join`,

  // OLD END-POINTS
  Register: `${server}/portal/auth/register`,
  ResendVerificationEmail: `${server}/portal/auth/resend-verification`,
  VerifyEmail: `${server}/portal/auth/verify-email`,
  BucketProfile: `${server}/bucket/profile`,
  JoinBucket: `${server}/portal/bucket/join`,
  Login: `${server}/portal/login`,
  VerifyLoginOTP: `${server}/portal/login-verifyotp`,
  ResendLoginOTP: `${server}/portal/login-resendotp`,
  FetchTimelineCharts: `${server}/portal/timeline-charts`,
  iAmWatchingGoLive: `${server}/portal/i-am-watching-live`
  // ResetPasswordTokenVerification: `${server}/portal/recover-password`,
}

export default CONFIG
