import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface RedirectPortalInterface {
  path: string | null
}
const initialState: RedirectPortalInterface = {
  path: null
}

const redirectSlice = createSlice({
  name: 'redirect',
  initialState,
  reducers: {
    updateRedirect: (state, action: PayloadAction<RedirectPortalInterface>) => {
      return action.payload
    }
  }
})

export const { updateRedirect } = redirectSlice.actions
export default redirectSlice.reducer
