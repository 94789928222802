import ReactDOM from 'react-dom'
import 'url-search-params-polyfill'
// React Suite Styles
import 'rsuite/lib/styles/index.less'
import 'react-phone-input-2/lib/style.css'
import 'pappu-editor/dist/style.css'
import 'react-credit-cards/es/styles-compiled.css'
import 'react-awesome-button/dist/themes/theme-amber.css'
import 'react-datepicker/dist/react-datepicker.css'

import App from './App'
import * as serviceWorker from './serviceWorker'

console.info('PORTAL VERSION: 1.0.0')

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  console.log('DEVELOPMENT MODE')
} else {
  // Setup Plausible
  const { head } = document
  const scripts = head.getElementsByTagName('script')
  const plausibleScript = Array.from(scripts).filter((script) => script.src === 'https://plausible.io/js/plausible.js')
  if (plausibleScript.length === 0) {
    const script = document.createElement('script')
    script.setAttribute('data-domain', 'portal.advanced-wellness.org')
    script.src = 'https://plausible.io/js/plausible.js'
    document.head.appendChild(script)
  }
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
