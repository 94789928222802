import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UsersPresenceStatusInterface {
  [id: string]: string
}
interface updateStatusInterface {
  id: string
  status: string
}

const initialState: UsersPresenceStatusInterface = {}

const usersStatusSlice = createSlice({
  name: 'UsersPresenceStatus',
  initialState,
  reducers: {
    UpdateStatus: (state, action: PayloadAction<updateStatusInterface>) => {
      const { id, status } = action.payload
      state[id] = status
    },
    Clean: (state, action: PayloadAction<{}>) => {
      state = {}
    }
  }
})

export const { UpdateStatus, Clean } = usersStatusSlice.actions
export default usersStatusSlice.reducer
