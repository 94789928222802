import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface GoLiveRedirect {
  [key: string]: boolean
}
const initialState: GoLiveRedirect = {}

const redirectSlice = createSlice({
  name: 'golive-prevent-redirect',
  initialState,
  reducers: {
    goLivePreventRedirect: (state, action: PayloadAction<{ ChartID: string }>) => {
      return {
        ...state,
        ChartID: true
      }
    }
  }
})

export const { goLivePreventRedirect } = redirectSlice.actions
export default redirectSlice.reducer
