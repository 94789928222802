import { combineReducers } from '@reduxjs/toolkit'

import UserReducer from './reducers/User/reducer'
import SystemFlagsReducer from './reducers/SystemFlags/reducer'
import UsersPresenceStatus from './reducers/UsersPresenceStatus/reducer'
import PathHistory from './reducers/PathHistory/reducer'
import Redirect from './reducers/Redirect'
import GoLivePreventRedirect from './reducers/GoLiveRedirect'

const rootReducer = combineReducers({
  User: UserReducer,
  SystemFlags: SystemFlagsReducer,
  UsersPresenceStatus,
  PathHistory,
  redirect: Redirect,
  goLivePreventRedirect: GoLivePreventRedirect
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
