import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface PathHistoryInterface {
  createChart: boolean
  brandingid: string
  shadowID: string
}
const initialState: PathHistoryInterface = {
  createChart: false,
  brandingid: '',
  shadowID: ''
}

const userSlice = createSlice({
  name: 'PathHistory',
  initialState,
  reducers: {
    updateData: (state, action: PayloadAction<PathHistoryInterface>) => {
      return action.payload
    }
  }
})

export const { updateData } = userSlice.actions
export default userSlice.reducer
