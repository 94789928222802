import React, { Component, lazy, Suspense } from 'react'
import { Loader } from 'rsuite'
import { connect } from 'react-redux'
import Axios, { AxiosError, AxiosResponse } from 'axios'
import CookieConsent from 'react-cookie-consent'
import { PatientForSelf as User, PatientForSelf } from '../../../interfaces/Patient'

import { RootState } from './Redux/reducers'

import APIConfig from './APIConfig'
import { userLogin } from './Redux/reducers/User/reducer'
import { userLoggedIn } from './Redux/reducers/SystemFlags/reducer'
import ErrorBoundary from './ErrorBoundary'

const OpenRoutes = lazy(() => import('./OpenRoutes'))
const RestrictedRoutes = lazy(() => import('./RestrictedRoutes'))

interface State {
  isBooting: boolean
}

interface Props {
  isLoggedIn: boolean
  userLoggedIn: () => void
  userLogin: (user: User) => void
}

class BootRoutes extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isBooting: true
    }
  }

  componentDidMount() {
    Axios.get(APIConfig.Boot)
      .then((res: AxiosResponse<{ User: PatientForSelf }>) => {
        console.log('boot result:', res, window.location)
        this.props.userLogin(res.data.User)
        this.props.userLoggedIn()
        this.setState({
          isBooting: false
        })
      })
      .catch((err: AxiosError) => {
        this.setState({
          isBooting: false
        })
      })
  }

  render() {
    if (this.state.isBooting) {
      return (
        <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Loader size="md" />
        </div>
      )
    }
    return (
      <div>
        {this.props.isLoggedIn && (
          <ErrorBoundary id="RestrictedRoutes">
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <RestrictedRoutes />
            </Suspense>
          </ErrorBoundary>
        )}
        {!this.props.isLoggedIn && (
          <ErrorBoundary id="OpenRoutes">
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <OpenRoutes />
            </Suspense>
          </ErrorBoundary>
        )}

        {!localStorage.getItem('cookieConsent') && (
          <CookieConsent
            onAccept={() => {
              localStorage.setItem('cookieConsent', 'true')
            }}
          >
            This website uses cookies to enhance the user experience.
          </CookieConsent>
        )}
      </div>
    )
  }
}
const mapStateToProps = (state: RootState) => ({
  isLoggedIn: state.SystemFlags.isLoggedIn
})

const mapDispatchToProps = {
  userLoggedIn,
  userLogin
}

export default connect(mapStateToProps, mapDispatchToProps)(BootRoutes)
