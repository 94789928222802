import { ReactElement } from 'react'
import { Provider } from 'react-redux'
import { Switch, BrowserRouter, Route } from 'react-router-dom'
import axios from 'axios'
import store from './Redux/store'
import BootRoutes from './BootRoutes'

import './App.css'
import OpenDummyPatient from './OpenDummyPatient'
import OneLinkLogin from './OneLinkLogin'

axios.defaults.withCredentials = true

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Renderer />
      </BrowserRouter>
    </Provider>
  )
}

export default App

function Renderer(): ReactElement {
  return (
    <Switch>
      <Switch>
        <Route path="/one-link-login/:LoginID" exact component={OneLinkLogin} />
        <Route path="/open/dummy/:PatientID/:BrandingID" exact component={OpenDummyPatient} />
        <Route path="/" component={BootRoutes} />
      </Switch>
    </Switch>
  )
}
