import Axios, { AxiosError, AxiosResponse } from 'axios'
import React, { ReactElement, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Alert } from 'rsuite'
import APIConfig from './APIConfig'

export default function OpenDummyPatient(): ReactElement {
  const params = useParams<{ PatientID: string; BrandingID: string }>()
  const history = useHistory()
  useEffect(() => {
    Axios.get(`${APIConfig.logoutIfExists}`)
      .then((respp: AxiosResponse) => {
        Axios.post(APIConfig.bootDummyPatient, {
          PatientID: params.PatientID,
          BrandingID: params.BrandingID
        })
          .then((res) => {
            if (res.data.success) {
              history.push('/')
              window.location.reload()
            } else {
              Alert.error('Error loading dummy patient.')
              setTimeout(() => {
                history.push('/')
                window.location.reload()
              }, 3000)
            }
          })
          .catch((err) => {
            Alert.error('Error loading dummy patient')
            setTimeout(() => {
              history.push('/')
              window.location.reload()
            }, 3000)
          })
      })
      .catch((err: AxiosError) => {
        Alert.closeAll()
        Alert.error('Internet Issue or System Down')
        console.log('error in logout is: ', err)
      })
  }, [history, params.BrandingID, params.PatientID])
  return (
    <div style={{ marginTop: 20 }}>
      <p>Opening Dummy Patient Account !!!</p>
    </div>
  )
}
