import { createSlice } from '@reduxjs/toolkit'

export interface SystemFlags {
  isLoggedIn: boolean
  publicSocket: Date | null
  privateSocket: Date | null
}

const initialState: SystemFlags = {
  isLoggedIn: false,
  publicSocket: null,
  privateSocket: null
}

const SystemFlagsSlice = createSlice({
  name: 'SystemFlags',
  initialState,
  reducers: {
    userLoggedIn(state) {
      state.isLoggedIn = true
    },
    publicSocketConnect(state) {
      state.publicSocket = new Date()
    },
    privateSocketConnect(state) {
      state.privateSocket = new Date()
    }
  }
})

export const { userLoggedIn, publicSocketConnect, privateSocketConnect } = SystemFlagsSlice.actions
export default SystemFlagsSlice.reducer
