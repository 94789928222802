import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PatientForSelf as User } from '../../../../../../interfaces/Patient'

const initialState: User = { _id: '', email: '', fullName: '' }

const userSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    userLogin: (state, action: PayloadAction<User>) => {
      return action.payload
    },
    updateAvatarURL: (state, action: PayloadAction<string>) => {
      state.avatarURL = action.payload
    },
    updateProfile: (_state, action: PayloadAction<updateDataInterface>) => {
      const { fullName, mobileNumber, date_of_birth, address, city, state, zipCode, country } = action.payload
      _state = { ..._state, fullName, mobileNumber, date_of_birth, address, city, state, zipCode, country }
      return _state
    },
    updateUser: (_state, action: PayloadAction<User>) => {
      _state = { ..._state, ...action.payload }
      return _state
    }
  }
})

export const { userLogin, updateAvatarURL, updateProfile, updateUser } = userSlice.actions
export default userSlice.reducer

export interface updateDataInterface {
  fullName: string
  mobileNumber: string
  date_of_birth: string
  address: string
  city: string
  state: string
  zipCode: string
  country: string
}
