import axios, { AxiosError } from 'axios'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Button, Loader } from 'rsuite'
import APIConfig from '../APIConfig'
import { updateRedirect } from '../Redux/reducers/Redirect'
import { userLoggedIn } from '../Redux/reducers/SystemFlags/reducer'
import { userLogin } from '../Redux/reducers/User/reducer'

export default function OneLinkLogin(): ReactElement {
  const [loggingIn, setLogginIn] = useState<boolean>(true)
  const [expired, setExpired] = useState<boolean>(false)
  const [invalid, setInvalid] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const dispatch = useDispatch()
  const params = useParams<{ LoginID: string }>()
  const history = useHistory()

  useEffect(() => {
    setLogginIn(true)
    axios
      .post(APIConfig.onlinklogin, {
        LoginID: params.LoginID
      })
      .then((res) => {
        if (res.data.expired) {
          setExpired(true)
          setLogginIn(false)
          return
        }

        if (res.data.invalid) {
          setInvalid(true)
          setLogginIn(false)
          return
        }

        if (res.data.User) {
          dispatch(userLogin(res.data.User))
          if (res.data.payload.redirect) {
            dispatch(updateRedirect({ path: res.data.payload.redirect }))
          }
          dispatch(userLoggedIn())
          history.push('/')
        }
      })
      .catch((err: AxiosError) => {
        setError(err.response?.data.message || 'Error Using one link login!')
      })
  }, [dispatch, history, params.LoginID])

  if (loggingIn) {
    return (
      <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <p style={{ textAlign: 'center' }}>Signing In. Please wait.</p>
        <Loader />
      </div>
    )
  }

  if (expired) {
    return (
      <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <p style={{ textAlign: 'center' }}>This link has been expired. Please login using credentials by clicking the button below.</p>
        <Link to="/">
          <Button>Click here to login!</Button>
        </Link>
      </div>
    )
  }

  if (invalid) {
    return (
      <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <p style={{ textAlign: 'center' }}>Invalid Link. Please login using credentials by clicking the button below.</p>
        <Link to="/">
          <Button>Click here to login!</Button>
        </Link>
      </div>
    )
  }

  if (error) {
    return (
      <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <p style={{ textAlign: 'center' }}>{error} Please login using credentials by clicking the button below.</p>
        <Link to="/">
          <Button>Click here to login!</Button>
        </Link>
      </div>
    )
  }

  return (
    <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <p style={{ textAlign: 'center' }}>Please login using credentials by clicking the button below.</p>
      <Link to="/">
        <Button>Click here to login!</Button>
      </Link>
    </div>
  )
}
